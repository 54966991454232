<template>
  <div
    class="pathadvice-lead-form pathadvice-lead-form--mobile"
    :class="{
      'pathadvice-lead-form--chat': isInChat
    }"
    :style="{
      '--contact-form-font-color': dialog.contactForm.fontColor,
      '--contact-form-background': dialog.contactForm.isDefaultContactFormInsideChat ? '#EFF1F5' : toolBarBackground,
      '--contact-form-darken-background': darkСontactFormSendButtonBackgroundColor,
      '--contact-form-dark-background': darkСontactFormSendButtonBackgroundColor
    }"
  >
    <template v-if="!isInChat">
      <button class="pathadvice__button-back" @click.prevent="goBackToCreateNewRequest">
        <ArrowIcon :color="'#fff'" @click="goBackToCreateNewRequest" />
      </button>

      <div class="pathadvice-lead-form__title" :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`">
        {{ $tp('vue.leadFormTitle') }}
      </div>
    </template>

    <div class="pathadvice-lead-form__description" :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`">
      {{ isInChat ? $tp('vue.leadFormTextInChat') : $tp('vue.leadFormText') }}
    </div>

    <div class="pathadvice-lead-form__fields">
      <div>
        <div
          class="pathadvice__contact-form__main-container__form__field"
          :class="{
            'pathadvice__contact-form__main-container__form__field_invalid': errors.first('name')
          }"
        >
          <input
            name="name"
            key="name"
            class="pathadvice__contact-form__main-container__form__input"
            :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
            :placeholder="$tp('buttonAndHints.name') | modifyInputPlaceholder(true)"
            v-model="name"
            v-validate="{
              required: true,
              max: 80
            }"
          />

          <p
            v-if="errors.first('name')"
            class="pathadvice__contact-form__main-container__form__field__error-checkbox"
            :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`"
          >
            * {{ errors.first('name') }}
          </p>

          <UserIcon class="pathadvice__contact-form__main-container__form__field__icon" color="#262629" />
        </div>

        <div
          class="pathadvice__contact-form__main-container__form__field"
          :class="{
            'pathadvice__contact-form__main-container__form__field_invalid': errors.first('email')
          }"
        >
          <input
            name="email"
            key="email"
            class="pathadvice__contact-form__main-container__form__input"
            :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
            :placeholder="$tp('buttonAndHints.emailAddress') | modifyInputPlaceholder(true)"
            v-model="email"
            v-validate="{
              required: true,
              email: true
            }"
          />

          <p
            v-if="errors.first('email')"
            class="pathadvice__contact-form__main-container__form__field__error"
            :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`"
          >
            * {{ errors.first('email') }}
          </p>

          <MailIcon class="pathadvice__contact-form__main-container__form__field__icon" color="#262629" />
        </div>

        <div
          v-if="leadForm && leadForm.isPrivacyInformationEnabled"
          class="mb-5 pathadvice__contact-form__main-container__form__field"
          :class="{
            'pathadvice__contact-form__main-container__form__field_invalid': errors.first('privacy-agreement')
          }"
        >
          <div
            class="flex flex-row"
            :class="{
              'pathadvice__contact-form__privacy-policy pt-0': errors.first('privacy-agreement')
            }"
          >
            <div>
              <vs-checkbox
                class="pathadvice__contact-form__privacy-policy__checkbox"
                name="privacy-agreement"
                key="privacy-agreement"
                type="checkbox"
                v-validate="'required:true'"
                :style="{
                  '--contact-form-darken-background': darkСontactFormSendButtonBackgroundColor,
                  '--contact-form-font-color': darkСontactFormSendButtonBackgroundColor
                }"
                v-model="hasAgreedToPrivacy"
              >
                <!-- <input name="privacy-agreement" key="privacy-agreement" class="custom-input-hidden" type="checkbox" v-validate="'required:true'" v-model="hasAgreedToPrivacy" />

                <span class="custom-checkbox__check" /> -->
              </vs-checkbox>
            </div>

            <div>
              <span v-html="leadForm.privacyInformationHtml" :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`"></span>
            </div>
          </div>

          <p
            v-if="errors.first('privacy-agreement')"
            class="pathadvice__contact-form__main-container__form__field__error-checkbox"
            :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`"
          >
            * {{ errors.first('privacy-agreement') }}
          </p>
        </div>
      </div>

      <div class="pathadvice__contact-form__main-container__form__actions pathadvice__contact-form__main-container__form__footer">
        <button
          class="pathadvice__contact-form__main-container__form__actions__btn"
          :disabled="isLoadingSubmit"
          :style="`background: ${
            dialog.contactForm.isDefaultContactFormInsideChat ? '#494A4E' : darkСontactFormSendButtonBackgroundColor
          }; color: #fff !important`"
          @click.prevent="submit"
        >
          {{ isInChat ? $tp('vue.send') : $tp('vue.startConversation') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import 'firebase/firestore'

import sanitizeHtml from 'sanitize-html'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const isBetween = require('dayjs/plugin/isBetween')
const customParseFormat = require('dayjs/plugin/customParseFormat')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)

import { mapGetters } from 'vuex'

import ArrowIcon from './icons/ArrowIcon'
import UserIcon from './icons/UserIcon'
import MailIcon from './icons/MailIcon'

export default {
  name: 'LeadForm',

  components: {
    ArrowIcon,
    UserIcon,
    MailIcon
  },

  props: {
    leadForm: {
      type: Object,
      required: false
    },
    isInChat: {
      type: Boolean
    },
    modifiedMessages: {
      type: Array
    },
    activeLeadFormId: {
      type: String
    }
  },

  data: () => ({
    isLoadingSubmit: false,
    name: '',
    email: '',
    hasAgreedToPrivacy: false
  }),

  computed: {
    ...mapGetters({
      dialog: 'campaign/dialog',
      visitorId: 'visitorId',
      agentInfo: 'campaign/agentInfo'
    }),

    toolBarBackground() {
      return this.dialog.step2ButtonColor || '#3B86F7'
    },

    darkСontactFormSendButtonBackgroundColor() {
      return this.lightenDarkenColor(this.toolBarBackground, -40)
    }
  },

  methods: {
    sanitizeHtml,

    filteredMessages() {
      if (this.modifiedMessages && this.modifiedMessages.length) {
        const filteredByVisitor = this.modifiedMessages.filter((el) => el.sender === 'visitor' && (!el.id || (el.id && el.leadForm)))

        return filteredByVisitor
      }

      return []
    },

    lightenDarkenColor(color, percent) {
      if (color[0] === '#') {
        color = color.slice(1)
      }

      const num = parseInt(color, 16)
      const amt = Math.round(2.55 * percent)
      const R = (num >> 16) + amt
      const B = ((num >> 8) & 0x00ff) + amt
      const G = (num & 0x0000ff) + amt
      /* eslint-disable no-mixed-operators */
      return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255))
        .toString(16)
        .slice(1)}`
    },

    goBackToCreateNewRequest() {
      this.$emit('goBackToCreateNewRequest')
    },

    async sendMessageRQ(msg = '', sender = 'visitor') {
      try {
        const message = {
          sender,
          message: this.sanitizeHtml(msg),
          time: new Date(),
          chatTime: dayjs().tz(this.$defaultTimezone).diff('2021-01-01'),
          type: 'lead-form',
          leadForm: {
            name: this.name,
            email: this.email
          }
        }

        if (this.agentInfo && this.agentInfo.connectedAgentId) {
          message.agentInfo = this.agentInfo
        }

        const visitorUpdate = {
          visitorDisplayName: this.name,
          shouldNotifyAgents: false,
          chats: this.$firebase.firestore.FieldValue.arrayUnion(message),
          callTypeState: 'visitor-lead-form-filling',
          visitorMessages: this.$firebase.firestore.FieldValue.increment(1),
          agentMessages: this.$firebase.firestore.FieldValue.delete(),
          activeLeadFormId: this.$firebase.firestore.FieldValue.delete(),
          isPreparedLeadFormToBeSent: true
        }

        await this.$db.collection('visitors').doc(this.visitorId).update(visitorUpdate)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },

    async submit() {
      const result = await this.$validator.validateAll()

      if (!result) {
        return
      }

      this.isLoadingSubmit = true

      const loader = this.$loading.show()

      try {
        await this.sendMessageRQ()
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }

      this.name = ''
      this.email = ''
      this.hasAgreedToPrivacy = ''

      this.$nextTick(() => {
        this.errors.clear()
        this.$validator.reset()
      })

      loader.hide()

      this.isLoadingSubmit = false

      this.$emit('submitLeadForm')
    }
  },

  filters: {
    modifyInputPlaceholder(string, isRequired) {
      return isRequired ? `${string}*` : string
    }
  }
}
</script>

<style lang="scss">
.pathadvice__contact-form__privacy-policy__checkbox {
  input:checked + .vs-checkbox {
    border-width: 2px !important;
    border-style: solid !important;
    border-color: #fff !important;
    background: rgba(var(--vs-primary), 1);
  }
}
</style>

<style lang="scss" scoped>
.pathadvice {
  &__button-back {
    cursor: pointer;
  }
}

.pathadvice-lead-form {
  padding: 20px;
  width: 100%;
  height: 100%;
  background: var(--contact-form-background);
  border-bottom-right-radius: 0;
  overflow: hidden;

  &--mobile {
    .pathadvice-lead-form {
      &__title {
        margin-top: 80px;
        font-size: 24px;
        line-height: 29px;
      }

      &__description {
        margin-top: 40px;
        padding: 0 14px;
        font-size: 20px;
        line-height: 27px;
      }
    }
  }

  &--chat {
    display: block;
    position: static;
    padding: 20px 12px 18px;
    border-radius: 6px;
    border-bottom-right-radius: 0;

    .pathadvice-lead-form {
      &__description {
        margin-top: 0;
        padding: 0;
        font-size: 14px;
        line-height: 19px;
      }

      &__fields {
        margin-top: 15px;
      }
    }

    .pathadvice__contact-form__main-container__form {
      &__footer {
        margin-top: 15px;
      }
    }
  }

  &__title {
    margin-top: 40px;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  &__description {
    margin-top: 20px;
    padding: 0;
    color: #ffffff;
    font-size: 16px;
    line-height: 20px;
  }

  &__fields {
    margin-top: 24px;
  }
}

.pathadvice__contact-form__main-container__form {
  &__field {
    margin-top: 10px;

    &__icon {
      position: absolute;
      top: 16px;
      left: 15px;
      width: 15px;
      height: 15px;
      color: #262629;
      stroke: #262629;
    }
  }

  &__actions {
    &__btn {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 15px 10px;
      color: inherit;
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      text-align: center;
      color: #fff;
      box-shadow: 0px 2.79165px 6.97912px rgba(0, 0, 0, 0.5);
      border-radius: 14px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  &__footer {
    margin-bottom: 0;
  }
}
</style>
